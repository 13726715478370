import React from 'react';
import Select from 'react-select';

const MonFormulaire = (props) => {


    const handleSelectionChange = (selectedOption) => {
        // Faire quelque chose avec l'élément sélectionné
        props.setPropositions([...props.propositions, selectedOption.label])
        props.setSelectedOption(null);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            // Faire quelque chose avec l'élément sélectionné
            console.log('Élément sélectionné :', e.target.value);
        }
    };

    let possibleElements = props.breeds.filter(element => props.propositions.indexOf(element.label) < 0);

    return (
        <div className="form">
            <Select
                options={possibleElements}
                onChange={handleSelectionChange}
                onKeyPress={handleKeyPress}
                isSearchable
                value={props.selectedOption}  // Set the value prop to control the input field

            />
        </div>
    );
};

export default MonFormulaire;
