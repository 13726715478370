import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Button, Col, Container, Row } from 'react-bootstrap';
import MonFormulaire from './components/Form';

import './App.css';
import DogInfo from './components/DogInfo';
import Propositions from './components/Propositions';


function App() {

  const [dog, setDog] = useState(null);
  const [gameEnded, setGameEnded] = useState(true);
  const [breeds, setBreeds] = useState(null);
  const [imageUrl, setImageUrl] = useState("/loading.gif");
  const [propositions, setPropositions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  const [answer, setAnswer] = useState("Voir la réponse");

  const showAnswer = async () => {
    setAnswer(dog['Race'])
  }

  const fetchAPI = async () => {
    try {
      setImageUrl('/loading.gif');
      setGameEnded(false);
      setAnswer("Voir la réponse");
      setPropositions([]);

      // Remplacez l'URL ci-dessous par l'URL réelle de votre API
      const response = await axios.get('https://refuge-rennes.fr/api_breed/dog');
      let data = response.data[0];
      setDog(data);
      setImageUrl('https://www.la-spa.fr' + data['medias']);

      if (!breeds) {
        // Remplacez l'URL ci-dessous par l'URL réelle de votre API
        const response2 = await axios.get('https://refuge-rennes.fr/api_breed/breeds');
        let data2 = response2.data;

        const options = data2.map((label, index) => ({
          value: `item${index + 1}`,
          label: label,
        }));
        setBreeds(options);
      }

    } catch (error) {
      console.error('Erreur lors de la récupération des données de l\'API', error);
    }
  };

  return (
    <Container>
      <h1>Devine la race</h1>
      <p>
        {
          (!gameEnded && <>Partie en cours</>) || <>Partie terminée</>
        }
      </p>
      <p>
        <Button variant='success' onClick={fetchAPI}>Nouvelle partie</Button> {dog && <Button variant='info' onClick={showAnswer}>
          {(!gameEnded && <>{answer}</>) || <>{dog['Race']}</>}
        </Button>}
      </p>
      <hr />
      <Row xs={1} md={2}>
        <Col>
          {
            dog && breeds && <>
              <div className="photo">
                <img src={imageUrl} alt="logo" onError={fetchAPI} />
              </div>
            </>
          }
        </Col>
        <Col>
          {
            dog && breeds && <>
              <DogInfo dog={dog} />

              {
                !gameEnded && <MonFormulaire
                  breeds={breeds}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  propositions={propositions}
                  setPropositions={setPropositions}
                />
              }

              <Propositions
                gameEnded={gameEnded}
                setGameEnded={setGameEnded}
                propositions={propositions} breed={dog['Race']} />
            </>
          }
        </Col>
      </Row>
    </Container>
  );
}

export default App;
