import React from 'react';

const DogInfo = (props) => {


    return (
        <>

            <h2>{props.dog['name']}</h2>
            <p>{props.dog['Refuge']}</p>
        </>
    );
};

export default DogInfo;
