import React from 'react';

const Propositions = (props) => {


    return (
        <ul id='propositions'>
            {
                [...props.propositions].reverse().map((item) => {
                    let state = 'mauvais';
                    if (item === props.breed) { state = 'bon'; props.setGameEnded(true); }

                    return <li key={item} className={state}>{item}</li>
                })
            }
        </ul>
    );
};

export default Propositions;
